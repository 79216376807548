import {
  ICartItem,
  ICartItemType,
  ICashbackType,
  IItemBody,
  IShoppingCart,
  ReceiptItem
} from '@poinz/api';

export const formatShoppingCartItem = (item: ICartItem): ICartItem => {
  const cashbackValue = item.cashback
    ? item.cashbackType === ICashbackType.AMOUNT
      ? item.cashback * item.quantity
      : item.cashback * item.price * item.quantity
    : 0;

  const cashSavingValue = item.originalPrice
    ? (item.originalPrice - item.price) * item.quantity
    : 0;

  return { ...item, cashbackValue, cashSavingValue };
};

export const formatShoppingCartResponse = (shoppingCart: IShoppingCart): IShoppingCart => {
  const items = shoppingCart.items.map(item => formatShoppingCartItem(item));
  const { cashback, cashSaving } = items.reduce(
    (acc, curr) => ({
      cashback: acc.cashback + curr.cashbackValue,
      cashSaving: acc.cashSaving + curr.cashSavingValue
    }),
    { cashback: 0, cashSaving: 0 }
  );

  return { ...shoppingCart, items, cashback, cashSaving };
};

export const findItem = (items: ICartItem[], item: IItemBody) => {
  return items.find(cartItem => {
    if (cartItem.itemType === item.itemType) {
      if (item.itemType === ICartItemType.DEAL) {
        return item.dealId === cartItem.dealId && item.dealOptionId === cartItem.dealOptionId;
      }

      if (cartItem.itemType === ICartItemType.GIFT_CARD) {
        return item.giftCardId === cartItem.giftCardId && item.price === cartItem.price;
      }
    }
  });
};

export const getNumberOfTotalCartItems = (items?: Array<ICartItem | ReceiptItem>) => {
  if (!items?.length) {
    return 0;
  }

  return items.reduce(function (acc, item) {
    return acc + item.quantity;
  }, 0);
};

export const ITEM_TYPE_KEYS_MAP = {
  [ICartItemType.DEAL]: 'shop.deal.deal',
  [ICartItemType.GIFT_CARD]: 'shop.giftCards.giftCard'
};
