import clsx from 'clsx';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
  id?: string;
}

const View: React.FC<Props> = ({ className, children, id }: Props) => {
  return (
    <div id={id} className={clsx('view mx-auto px-6', className)}>
      {children}
    </div>
  );
};

View.displayName = 'View';

export default View;
