import { useAuth } from 'contexts/authUser/store';
import { useRouter } from 'next/router';
import React from 'react';
import { routes } from 'utils/routes';

const PROTECTED_PATHS = [
  routes.account.overview.index(),
  routes.account.information.index(),
  routes.account.wallet.assetType().index(),
  routes.account.wallet.assetType().id(),
  routes.account.cashback.balanceAndActivity(),
  routes.account.receipts.index(),
  routes.account.cashback.cards(),
  routes.account.receipts.index(),
  routes.receipt.referenceNumber(),
  routes.checkout.overview(),
  routes.checkout.purchase(),
  routes.checkout.success(),
  routes.checkout.slug()
];

const useProtectedRoutes = () => {
  const router = useRouter();
  const { loading, authUser } = useAuth();

  const isProtected = React.useMemo(
    () => !!PROTECTED_PATHS.find(path => path === router.pathname),
    [router.pathname]
  );

  React.useEffect(() => {
    if (!loading && !authUser && isProtected) {
      router.replace({
        pathname: routes.auth.login(),
        query: { redirectTo: router.asPath, ...router.query }
      });
    }
  }, [loading, authUser, router, isProtected]);
};

export default useProtectedRoutes;
