import { StompSubscription } from '@stomp/stompjs';

export interface ISubscription extends StompSubscription {
  callback: (message: IMessage) => void;
}

export enum ISubscriptionEndpoint {
  USER_QUEUE_PUSH = '/user/queue/push'
}

export enum IMessageDataStatus {
  RESERVATION_SUCCESS = 'RESERVATION_SUCCESS',
  RESERVATION_FAILED = 'RESERVATION_FAILED',
  PURCHASE_FAILED = 'PURCHASE_FAILED',
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  PURCHASE_EXPIRED = 'PURCHASE_EXPIRED',
  PURCHASE_CANCELED = 'PURCHASE_CANCELED',
  PURCHASE_DATATRANS = 'PURCHASE_DATATRANS',
  PURCHASE_LOYALTY = 'PURCHASE_LOYALTY',
  CHECKOUT_EXPIRED = 'CHECKOUT_EXPIRED'
}

export enum IMessageEntityType {
  SWISS_LOYALTY_TRANSACTION = 'swissLoyaltyTransaction',
  DEALS = 'deals'
}

export enum IMessageDataType {
  CART = 'CART',
  REFRESH = 'REFRESH'
}

export interface IMessageData {
  status?: IMessageDataStatus;
  entityType?: IMessageEntityType;
  messageType: IMessageDataType;
}
export interface IMessage {
  data: IMessageData;
}

export interface ISocketContext {
  isLoading: boolean;
  isConnected: boolean;
  subscribe?: (
    path: ISubscriptionEndpoint,
    callback: (message: IMessage) => void
  ) => StompSubscription | undefined;
  unsubscribe?: (subscription?: StompSubscription) => void;
}
