import { IFlatAddress, IUserAddress, IUserAddressType, IUserAddressPayload } from '@poinz/api';

export const flatUserAddress = (userAddress: IUserAddress) => {
  const {
    firstName,
    lastName,
    address: { countryCode, streetNumber, city, street, zip }
  } = userAddress;

  return {
    firstName,
    lastName,
    zip,
    city,
    country: countryCode,
    street,
    number: streetNumber
  } as IFlatAddress;
};

export const formatFlatAddress = (flatAddress: IFlatAddress, type?: IUserAddressType) => {
  const {
    city,
    country: countryCode,
    street,
    number: streetNumber,
    zip,
    firstName,
    lastName
  } = flatAddress;

  return {
    address: { countryCode, streetNumber, city, street, zip },
    firstName,
    lastName,
    ...(type && { type })
  } as IUserAddressPayload;
};
