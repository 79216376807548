import { IWalletAssetType } from './wallet';

export const ASSET_TYPE_SLUG = '[assetType]';
export const ID_SLUG = '[id]';
export const REFFENCE_NUMBER_SLUG = '[referenceNumber]';

export enum IRouteSlugWildCard {
  ASSET_TYPE = '[assetType]',
  ID = '[id]',
  REFERENCE_NUMBER = '[referenceNumber',
  SLUG = '[slug]',
  CODE = '[code]'
}

export const routes = {
  home: {
    index: () => '/'
  },
  aboutUs: {
    index: () => '/about-us'
  },
  account: {
    information: {
      index: () => '/account/information'
    },
    overview: {
      index: () => '/account/overview'
    },
    cashback: {
      cards: () => `/account/cashback/cards`,
      balanceAndActivity: () => `/account/cashback/balance-and-activity`
    },
    wallet: {
      assetType: (
        asseetType: IWalletAssetType | IRouteSlugWildCard.ASSET_TYPE = IRouteSlugWildCard.ASSET_TYPE
      ) => ({
        index: () => `/account/wallet/${asseetType}`,
        id: (id: string | IRouteSlugWildCard.ID = IRouteSlugWildCard.ID) =>
          `/account/wallet/${asseetType}/${id}`
      })
    },
    receipts: {
      index: () => '/account/receipts'
    }
  },
  auth: {
    confirmAccount: () => '/auth/confirm-account',
    forgotPassword: () => '/auth/forgot-password',
    login: () => '/auth/login',
    register: () => '/auth/register',
    resetPassword: () => '/auth/reset-password'
  },
  becomePartner: {
    index: () => '/become-a-partner'
  },
  blog: {
    index: () => '/blog',
    slug: (slug: string | IRouteSlugWildCard.SLUG = IRouteSlugWildCard.SLUG) => `/blog/${slug}`
  },
  cashbackBrowserExtension: {
    index: () => '/cashback-browser-extension'
  },
  checkout: {
    overview: () => '/checkout/overview',
    purchase: () => '/checkout/purchase',
    slug: (slug: string | IRouteSlugWildCard.SLUG = IRouteSlugWildCard.SLUG) => `/checkout/${slug}`,
    success: (
      referenceNumber:
        | string
        | IRouteSlugWildCard.REFERENCE_NUMBER = IRouteSlugWildCard.REFERENCE_NUMBER
    ) => `/checkout/success/${referenceNumber}`
  },
  contactUs: {
    index: () => '/contact-us'
  },
  legal: {
    slug: (slug: string) => `/legal/${slug}`
  },
  receipt: {
    referenceNumber: (
      referenceNumber:
        | string
        | IRouteSlugWildCard.REFERENCE_NUMBER = IRouteSlugWildCard.REFERENCE_NUMBER
    ) => `/receipt/${referenceNumber}`
  },
  referral: {
    code: (code: string | IRouteSlugWildCard.CODE = IRouteSlugWildCard.CODE) => `/referral/${code}`
  },
  shop: {
    deal: {
      slug: (slug: string | IRouteSlugWildCard.SLUG = IRouteSlugWildCard.SLUG) =>
        `/shop/deal/${slug}`
    },
    affiliate: {
      slug: (slug: string | IRouteSlugWildCard.SLUG = IRouteSlugWildCard.SLUG) =>
        `/shop/affiliate/${slug}`
    },
    digitalVoucher: {
      slug: (slug: string | IRouteSlugWildCard.SLUG = IRouteSlugWildCard.SLUG) =>
        `/shop/digital-voucher/${slug}`
    },
    index: () => '/shop'
  },
  poinz: {
    poinzCards: {
      index: () => '/poinz/poinz-cards'
    }
  },
  downloadApp: {
    index: () => '/download-app'
  }
};

export const darkBackgroundRoutes = [routes.cashbackBrowserExtension.index()];

export const publicPaths = [
  routes.aboutUs.index(),
  routes.contactUs.index(),
  routes.home.index(),
  routes.downloadApp.index(),
  routes.poinz.poinzCards.index(),
  routes.blog.index(),
  routes.cashbackBrowserExtension.index(),
  routes.becomePartner.index()
];

export const APP_ENTRY_ROUTE = routes.home.index();

export const ACTIVE_CAMPAIGN_CODE = 'NETFLIX-LDJH';
