import * as React from 'react';
import { IApi } from 'api/api';
import { useRouter } from 'next/router';

const useApi = () => {
  const [api, setApi] = React.useState<IApi | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { locale } = useRouter();

  React.useEffect(() => {
    if (api && locale) {
      api.changeLanguage(locale as string);
    }
  }, [api, locale]);

  React.useEffect(() => {
    if (!api) {
      (async () => {
        try {
          setIsLoading(true);
          const _api = (await import('api/api')).default;
          setApi(_api);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [api]);

  return { api, isLoading };
};

export default useApi;
