import { keys, pick, transform } from 'lodash';
import { PIXEL_EVENTS } from './firebase/analytics';

const FB_PARAMS = {
  item_id: 'content_ids',
  currency: 'currency',
  value: 'value',
  item_type: 'content_type',
  quantity: 'num_items'
};

export const PIXEL_ID = '1310787396204416';

export const getFbParams = (params?: Record<string, any>) => {
  const data = transform(
    pick(params, keys(FB_PARAMS)),
    (obj: { [key in string]: any }, value: string | number, key: string) => {
      const fbVal = key === 'item_id' ? [value] : value;
      // eslint-disable-next-line no-param-reassign
      obj[FB_PARAMS[key as keyof typeof FB_PARAMS]] = fbVal;
    }
  );

  if (params?.items?.length) {
    params.items.forEach(item => {
      data.content_ids = [...(data.content_ids || []), item.item_id];
      data.content_type = 'product';
    });
  }

  return data;
};

const setUserId = (userId: string): void => {
  if (window.fbq) {
    window.fbq('init', PIXEL_ID, { external_id: userId });
  }
};

const track = (title: string, data?: Record<string, any>) => {
  const fbParams = getFbParams(data);

  if (window.fbq) {
    window.fbq('track', title, fbParams);
  }
};

const pageView = () => {
  if (window.fbq) {
    window.fbq('track', PIXEL_EVENTS.PAGE_VIEW);
  }
};

const facebookService = {
  track,
  pageView,
  setUserId
};

export default facebookService;
