import { FormattedAuthUser } from 'contexts/authUser/store';
import { Crisp } from 'crisp-sdk-web';
import { User } from 'firebase/auth';
import useApi from 'hooks/useApi';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { AUTH } from 'utils/firebase';
import analytics from './analytics';
import { getDeviceId } from './device';
import { SocialLoginProvider } from './authProviders';
import { getFirebaseErrorMessage } from './error';
import {
  RecaptchaVerifier as RecaptchaVerifierFirebase,
  getMultiFactorResolver as getMultiFactorResolverFirebase,
  PhoneMultiFactorGenerator as PhoneMultiFactorGeneratorFirebase,
  multiFactor as multiFactorFirebase,
  applyActionCode as applyActionCodeFirebase,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
  signOut as signOutFirebase,
  updatePassword as updatePasswordFirebase,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  confirmPasswordReset as confirmPasswordResetFirebase,
  PhoneAuthProvider,
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  onIdTokenChanged,
  onAuthStateChanged
} from 'firebase/auth';
import { formatAuthUser } from './general';
import { User as IUser } from '@poinz/api';
import { debounce } from 'lodash';
declare global {
  interface Window {
    resolver: any;
    verificationId: any;
    recaptchaVerifier: any;
    Trustpilot: any;
  }
}

export const AUTH_FLAGS = Object.freeze({
  ENTER_VERIFICATION_CODE: 'ENTER_VERIFICATION_CODE'
});

export const handleMultiFactorLogin = async (error: any) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifierFirebase(
      'recaptcha-container',
      { size: 'invisible' },
      AUTH
    );
  }

  // Has MFA enabled, sent the code
  if (error.code == 'auth/multi-factor-auth-required') {
    const resolver = getMultiFactorResolverFirebase(AUTH, error);
    window.resolver = resolver;
    // We will always have 1 factor, phone number
    if (resolver.hints[0].factorId === PhoneMultiFactorGeneratorFirebase.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      };

      const phoneAuthProvider = new PhoneAuthProvider(AUTH);
      // Send SMS verification code
      window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );

      // window.recaptchaVerifier.clear();
      return {
        nextStep: AUTH_FLAGS.ENTER_VERIFICATION_CODE,
        phone: (phoneInfoOptions.multiFactorHint as any).phoneNumber
      };
    } else {
      // Unsupported second factor.
    }
    // Throw all other errors like auth/wrong-password
  } else {
    error.message = { key: getFirebaseErrorMessage(error) };
    throw error;
  }
};

const useFirebaseAuth = () => {
  const [authUser, setAuthUser] = useState<FormattedAuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);
  const { api } = useApi();

  const getUser = useCallback(async () => {
    try {
      if (api && !loading && !!authUser?.email) {
        const deviceId = await getDeviceId();
        const [userData, refreshToken] = await api.user.mobileUser.getMe(deviceId);
        setUser(userData);
        if (refreshToken) {
          await AUTH.currentUser?.getIdToken(true);
        }
      }
    } catch (e) {
      console.error('User not loaded: ', e);
    }
  }, [api, loading, authUser?.email]);

  const fetchUserDebounced = useMemo(
    () => debounce(getUser, 2000, { leading: true, trailing: false }),
    [getUser]
  );

  const authStateChanged = useCallback(
    async _user => {
      if (!_user) {
        setAuthUser(null);
        setUser(null);
        setLoading(false);
        analytics.setUserId('');
      } else if (_user && !!api) {
        setLoading(true);
        const formattedUser = formatAuthUser(_user);
        setAuthUser(formattedUser);
        setLoading(false);
        if (!user) {
          fetchUserDebounced();
        }
        Crisp.user.setEmail(_user.email || '');
        const { claims } = await _user.getIdTokenResult();
        if (claims.id) {
          analytics.setUserId(claims.id);
          Crisp.session.setData({
            userId: claims.id
          });
        }
      }
    },
    [api, user, fetchUserDebounced]
  );

  const clear = () => {
    setAuthUser(null);
    setLoading(false);
  };

  const applyActionCode = async (token: string) => {
    return await applyActionCodeFirebase(AUTH, token);
  };

  const removeSecondFactor = async () => {
    const multiFactorUser = multiFactorFirebase(AUTH.currentUser as User);
    const options = multiFactorUser.enrolledFactors;
    // Ask user to select from the enrolled options.
    return await multiFactorUser.unenroll(options[0]);
  };

  const verifyCodeAndSignIn = async (code: string) => {
    // Verify the code and sign in
    const credential = PhoneAuthProvider.credential(window.verificationId, code);
    const multiFactorAssertion = PhoneMultiFactorGeneratorFirebase.assertion(credential);

    await window.resolver.resolveSignIn(multiFactorAssertion);
  };

  const verifyCodeAndEnrollToMFA = async (code: string) => {
    // Verify the code and enroll to MFA
    const credential = PhoneAuthProvider.credential(window.verificationId, code);
    const multiFactorAssertion = PhoneMultiFactorGeneratorFirebase.assertion(credential);

    await multiFactorFirebase(AUTH.currentUser as User).enroll(multiFactorAssertion);
  };

  const addPhoneAsMFALayer = async (phoneNumber: string) => {
    // Ask for a phone number so that we can set up MFA
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifierFirebase(
        'recaptcha-container',
        { size: 'invisible' },
        AUTH
      );
    }

    try {
      await multiFactorFirebase(AUTH.currentUser as User)
        .getSession()
        .then(async function (session) {
          // Specify the phone number and pass the MFA session.
          const phoneInfoOptions = {
            phoneNumber,
            session
          };

          const phoneAuthProvider = new PhoneAuthProvider(AUTH);

          // Send verification code
          window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            window.recaptchaVerifier
          );
        });
    } catch (error: any) {
      error.message = { key: getFirebaseErrorMessage(error) };
      throw error;
    }
  };

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    try {
      return await signInWithEmailAndPasswordFirebase(AUTH, email, password);
    } catch (error: any) {
      return await handleMultiFactorLogin(error);
    }
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return createUserWithEmailAndPasswordFirebase(AUTH, email, password);
  };

  const confirmPasswordReset = async (oobCode: string, password: string) => {
    try {
      return await confirmPasswordResetFirebase(AUTH, oobCode, password);
    } catch (error: any) {
      error.message = { key: getFirebaseErrorMessage(error) };
      throw error;
    }
  };

  const reauthenticateUserWithProvider = async (
    providerSlug: SocialLoginProvider
  ): Promise<void | { phone: string; nextStep: string }> => {
    let provider;

    switch (providerSlug) {
      case SocialLoginProvider.GOOGLE: {
        provider = new GoogleAuthProvider();
        break;
      }
      case SocialLoginProvider.FACEBOOK: {
        provider = new FacebookAuthProvider();
        break;
      }
      case SocialLoginProvider.APPLE: {
        provider = new OAuthProvider('apple.com');
        break;
      }
    }

    try {
      await reauthenticateWithPopup(AUTH.currentUser as User, provider);
    } catch (error: any) {
      return await handleMultiFactorLogin(error);
    }
  };

  const reauthenticateUserWithEmailCredential = async (
    currentPassword: string
  ): Promise<void | { phone: string; nextStep: string }> => {
    const credential = EmailAuthProvider.credential(
      AUTH.currentUser?.email as string,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(AUTH.currentUser as any, credential);
    } catch (error: any) {
      return await handleMultiFactorLogin(error);
    }
  };

  const updatePassword = async (currentPassword: string, newPassword: string) => {
    try {
      await reauthenticateUserWithEmailCredential(currentPassword);
      //user entered correct password and MFA is not enabled
      //re-authentication returns 200, and we update the password
      return await updatePasswordFirebase(AUTH.currentUser as any, newPassword);
    } catch (error: any) {
      // user entered correct password but MFA is enabled
      // re-authentication returns 400 auth/multi-factor-auth-required
      // don't re-authenticate, just update password
      if (error.code == 'auth/multi-factor-auth-required') {
        return await updatePasswordFirebase(AUTH.currentUser as any, newPassword);
      }

      // if there is another error, propagate it further
      error.message = { key: getFirebaseErrorMessage(error) };
      throw error;
    }
  };

  const sendEmailVerification = useCallback(async () => {
    if (api && user) {
      await api.user.userGeneral.resendConfirmationMail(user.id);
    }
  }, [api, user]);

  const signOut = async () => signOutFirebase(AUTH).then(clear);

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(AUTH, authStateChanged);

    return () => unsubscribe();
  }, [authStateChanged]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(AUTH, authStateChanged);

    return () => unsubscribe();
  }, [authStateChanged]);

  return {
    authUser,
    loading,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    user,
    getUser,
    confirmPasswordReset,
    updatePassword,
    addPhoneAsMFALayer,
    verifyCodeAndSignIn,
    verifyCodeAndEnrollToMFA,
    removeSecondFactor,
    applyActionCode,
    reauthenticateUserWithProvider,
    reauthenticateUserWithEmailCredential,
    sendEmailVerification
  };
};

export default useFirebaseAuth;
