import * as React from 'react';
import NextLink, { LinkProps } from 'next/link';

interface Props extends LinkProps {
  children?: React.ReactNode;
  className?: string;
  target?: React.HTMLAttributeAnchorTarget;
}
const Link: React.FC<Props> = props => {
  return <NextLink {...props} />;
};

export default Link;
