import * as React from 'react';

// call this in getServerSideProps or getStaticProps if you want to hide the page on production
export const hidePageOnProduction = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
    return {
      notFound: true
    };
  }
};

export const noIndex = () => <meta name="robots" content="noindex,nofollow" />;

export const noIndexOnProduction = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
    return noIndex();
  }
};
