import * as React from 'react';
import { IUserAddress, IUserAddressPayload, IUserAddressType } from '@poinz/api';
import api from 'api/api';
import { useShoppingCart } from 'contexts/shoppingCart';
import { flatUserAddress } from 'utils/addresses';
import { valuesAreEqual } from 'utils/general';

type IContextUserAddress = Record<IUserAddressType, IUserAddress | null>;

const defaultUserAddress = { [IUserAddressType.DELIVERY]: null, [IUserAddressType.INVOICE]: null };

export interface IUserAddressesContext {
  addresses: IUserAddress[];
  loading: boolean;
  address: IContextUserAddress;
  createAddress?: (newAddress: IUserAddressPayload) => Promise<void>;
  updateAddress?: (id: number, newAddress: IUserAddressPayload) => Promise<void>;
}

const UserAddressesContext = React.createContext<IUserAddressesContext>({
  addresses: [],
  loading: false,
  address: defaultUserAddress
});

interface Props {
  children: React.ReactNode;
}

const UserAddressesProvider = ({ children }: Props) => {
  const [addresses, setAddresses] = React.useState<IUserAddress[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [address, setAddress] = React.useState<IContextUserAddress>(defaultUserAddress);
  const { cart, updateCartAddress } = useShoppingCart();

  const getAddresses = React.useCallback(async () => {
    if (addresses.length) {
      return;
    }

    try {
      const userAddresses = await api.user.addresses.get();

      const userInvoiceAddress = userAddresses.find(adr => adr.type === IUserAddressType.INVOICE);
      const userDeliveryAddress = userAddresses.find(adr => adr.type === IUserAddressType.DELIVERY);

      if (updateCartAddress && userInvoiceAddress) {
        const formattedAddresses = {
          invoiceAddress: flatUserAddress(userInvoiceAddress),
          deliveryAddress: userDeliveryAddress && flatUserAddress(userDeliveryAddress)
        };

        const formattedCartAddresses = {
          invoiceAddress: cart?.invoiceAddress,
          deliveryAddress: cart?.deliveryAddress
        };

        if (!valuesAreEqual(formattedAddresses, formattedCartAddresses)) {
          setLoading(true);
          await updateCartAddress(formattedAddresses);
        }
      }
      setAddress({
        [IUserAddressType.INVOICE]: userInvoiceAddress || null,
        [IUserAddressType.DELIVERY]: userDeliveryAddress || null
      });

      setAddresses(userAddresses);
    } catch (error) {
      console.error({ error });
    } finally {
      setLoading(false);
    }
  }, [addresses.length, cart?.deliveryAddress, cart?.invoiceAddress, updateCartAddress]);

  React.useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const syncCartAddress = React.useCallback(
    async (userAddress: IUserAddress) => {
      const flatAddress = flatUserAddress(userAddress);
      const invoiceAddress =
        userAddress.type === IUserAddressType.INVOICE ? flatAddress : cart?.invoiceAddress;
      const deliveryAddress =
        userAddress.type === IUserAddressType.DELIVERY ? flatAddress : cart?.deliveryAddress;

      // update only if invoice address exists as it's mandatory
      if (updateCartAddress && invoiceAddress) {
        const formattedAddresses = {
          invoiceAddress,
          deliveryAddress
        };
        if (
          !valuesAreEqual(formattedAddresses, {
            invoiceAddress: cart?.invoiceAddress,
            deliveryAddress: cart?.deliveryAddress
          })
        ) {
          await updateCartAddress(formattedAddresses);
        }
      }
    },
    [cart?.deliveryAddress, cart?.invoiceAddress, updateCartAddress]
  );

  const createAddress = React.useCallback(
    async (newAddress: IUserAddressPayload) => {
      setLoading(true);

      const userAddress = await api.user.addresses.create(newAddress);

      await syncCartAddress(userAddress);

      setAddresses(addresses => [userAddress, ...addresses]);
      setAddress(address => ({ ...address, [userAddress.type]: userAddress }));

      setLoading(false);
    },
    [syncCartAddress]
  );

  const updateAddress = React.useCallback(
    async (id: number, newAddress: IUserAddressPayload) => {
      setLoading(true);

      const userAddress = await api.user.addresses.update(id, newAddress);

      await syncCartAddress(userAddress);

      setAddresses(addresses =>
        addresses.map(adr => (adr.id === userAddress.id ? userAddress : adr))
      );
      setAddress(address => ({ ...address, [userAddress.type]: userAddress }));

      setLoading(false);
    },
    [syncCartAddress]
  );

  const contextValue = React.useMemo(
    () => ({
      addresses,
      loading,
      address,
      createAddress,
      updateAddress
    }),
    [address, addresses, createAddress, loading, updateAddress]
  );

  return (
    <UserAddressesContext.Provider value={contextValue}>{children}</UserAddressesContext.Provider>
  );
};

export const useUserAddresses = (): IUserAddressesContext => {
  const contextValue = React.useContext(UserAddressesContext);

  return contextValue;
};
export default UserAddressesProvider;
