import * as React from 'react';
import useFirebaseAuth from 'utils/firebase/useFirebaseAuth';
import { emptyFunction } from 'utils/general';
import { User } from '@poinz/api';
import { SocialLoginProvider } from 'utils/firebase/authProviders';

export interface FormattedAuthUser {
  uid: string;
  email: string;
  emailVerified: boolean;
}
export interface AuthUserContext {
  authUser?: FormattedAuthUser | null;
  loading: boolean;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any> | undefined;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any> | undefined;
  signOut: () => Promise<void> | undefined;
  user?: User | null;
  getUser: () => Promise<void>;
  confirmPasswordReset: (oobcode: string, password: string) => Promise<void>;
  addPhoneAsMFALayer: (phoneNumber: string) => Promise<void>;
  verifyCodeAndSignIn: (code: string) => Promise<void>;
  verifyCodeAndEnrollToMFA: (code: string) => Promise<void>;
  removeSecondFactor: () => Promise<void>;
  updatePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  applyActionCode: (token: string) => Promise<void>;
  reauthenticateUserWithProvider: (
    providerSlug: SocialLoginProvider
  ) => Promise<void | { phone: string; nextStep: string }> | undefined;
  reauthenticateUserWithEmailCredential: (
    currentPassword: string
  ) => Promise<void | { phone: string; nextStep: string }> | undefined;
  sendEmailVerification: () => Promise<void>;
}

const authUserContext = React.createContext<AuthUserContext>({
  authUser: null,
  loading: true,
  signInWithEmailAndPassword: emptyFunction,
  createUserWithEmailAndPassword: emptyFunction,
  signOut: emptyFunction,
  confirmPasswordReset: emptyFunction,
  user: null,
  getUser: emptyFunction,
  addPhoneAsMFALayer: emptyFunction,
  verifyCodeAndSignIn: emptyFunction,
  verifyCodeAndEnrollToMFA: emptyFunction,
  removeSecondFactor: emptyFunction,
  reauthenticateUserWithProvider: emptyFunction,
  reauthenticateUserWithEmailCredential: emptyFunction,
  updatePassword: emptyFunction,
  applyActionCode: emptyFunction,
  sendEmailVerification: emptyFunction
});

const AuthUserProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
};

export const useAuth = (shapeFn?: (state: AuthUserContext) => any): any => {
  const state = React.useContext(authUserContext);

  if (shapeFn) {
    return shapeFn(state);
  }

  return state;
};
export default AuthUserProvider;
