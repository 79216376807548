import * as React from 'react';

const POOL_LIMIT = 7;

const usePoolRequest = () => {
  const limitReached = React.useRef(false);
  let counter = 1;
  const fn = async (callback: () => Promise<any>) => {
    try {
      if (limitReached.current) {
        return;
      }
      await new Promise(r => setTimeout(r, counter * 1000));
      await callback();
      counter = 1;
    } catch (e) {
      const err = JSON.parse(e as any);
      if (
        counter < POOL_LIMIT &&
        (err.status === 403 || err.status === 401 || err.status === 404)
      ) {
        counter++;
        await fn(callback);
      } else {
        limitReached.current = true;
        throw e;
      }
    }
  };

  return fn;
};

export default usePoolRequest;
