import { Language } from 'types/lang';
import { isChrome, isFirefox, isSafari, isEdge } from 'react-device-detect';
import { handleMultiFactorLogin } from './useFirebaseAuth';
import { AUTH } from '.';
const isSupportedBrowser = isChrome || isFirefox || isSafari || isEdge;

export enum SocialLoginProvider {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  EMAIL_AND_PASSWORD = 'EMAIL_AND_PASSWORD'
}

export async function signInWithSocialProvider(
  providerName: SocialLoginProvider,
  language: Language
) {
  try {
    let provider;

    const GoogleAuthProvider = (await import('firebase/auth')).GoogleAuthProvider;
    const FacebookAuthProvider = (await import('firebase/auth')).FacebookAuthProvider;
    const OAuthProvider = (await import('firebase/auth')).OAuthProvider;

    const signInWithPopup = (await import('firebase/auth')).signInWithPopup;
    const signInWithRedirect = (await import('firebase/auth')).signInWithRedirect;
    const getRedirectResult = (await import('firebase/auth')).getRedirectResult;

    const providerConstructors = {
      [SocialLoginProvider.GOOGLE]: GoogleAuthProvider,
      [SocialLoginProvider.FACEBOOK]: FacebookAuthProvider,
      [SocialLoginProvider.APPLE]: OAuthProvider
    };

    switch (providerName) {
      case SocialLoginProvider.APPLE:
        provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        break;
      default:
        provider = new providerConstructors[providerName]();
        break;
    }
    provider.setCustomParameters({ locale: language });
    AUTH.languageCode = language;
    if (isSupportedBrowser) {
      return await signInWithPopup(AUTH, provider);
    }
    await signInWithRedirect(AUTH, provider);
    return getRedirectResult(AUTH);
  } catch (e) {
    return await handleMultiFactorLogin(e);
  }
}
