import * as React from 'react';
import { VariantProps, cva, cx } from 'class-variance-authority';

const headingVariants = cva('', {
  variants: {
    variant: {
      h1: 'text-heading-lg lg:text-heading-xl',
      h2: 'text-heading-sm md:text-heading-md 2xl:text-heading-lg',
      h3: 'text-heading-mini md:text-heading-sm 2xl:text-heading-md',
      h4: 'text-heading-tiny md:text-heading-mini 2xl:text-heading-sm'
    },
    color: {
      default: 'text-neutral-900 dark:text-neutral-50',
      subtle: 'text-neutral-600 dark:text-neutral-400'
    }
  },
  defaultVariants: {
    color: 'default',
    variant: 'h3'
  }
});

export interface HeadingProps
  extends Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'>,
    VariantProps<typeof headingVariants> {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | null;
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = 'h3', color, tag, ...props }, ref) => {
    const renderHeading = (headingTag: 'h1' | 'h2' | 'h3' | 'h4' | null) => {
      switch (headingTag) {
        case 'h1':
          return (
            <h1
              className={cx(headingVariants({ variant, color, className }))}
              ref={ref}
              {...props}
            />
          );
        case 'h2':
          return (
            <h2
              className={cx(headingVariants({ variant, color, className }))}
              ref={ref}
              {...props}
            />
          );
        case 'h3':
          return (
            <h3
              className={cx(headingVariants({ variant, color, className }))}
              ref={ref}
              {...props}
            />
          );
        case 'h4':
          return (
            <h4
              className={cx(headingVariants({ variant, color, className }))}
              ref={ref}
              {...props}
            />
          );
        default:
          return null;
      }
    };

    if (tag) {
      return renderHeading(tag);
    }

    return renderHeading(variant);
  }
);

Heading.displayName = 'Heading';

export default Heading;
