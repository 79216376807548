import { useRouter } from 'next/router';
import { Language } from 'types/lang';
import * as React from 'react';
import useMatchWithCurrentPathname from 'hooks/useMatchWithCurrentPathname';
import { routes } from './routes';

export const useGetCanonicalLinks = () => {
  const { asPath, locale } = useRouter();

  const pathname = asPath.split('?')?.[0];
  // home page - don't inclide pathname to avoid redirects (poinz.ch/de/ => poinz.ch/de)
  const formattedPathname = pathname === '/' ? '' : pathname;

  return (
    <link rel="canonical" href={`${process.env.NEXT_PUBLIC_HOST}/${locale}${formattedPathname}`} />
  );
};

export const useGetHreflangLinks = () => {
  const router = useRouter();
  const pathname = router.asPath.split('?')?.[0];
  const matchWithCurrentPathname = useMatchWithCurrentPathname();

  // extract dummy params from dynamic routes
  const dynamicParams = router.pathname
    .split('/')
    .filter((segment: string) => segment.startsWith('[') && segment.endsWith(']'))
    .map((segment: string) => segment.slice(1, -1)); // Remove brackets to get the param name

  // create a filtered query object excluding dynamic params
  const filteredQuery = Object.keys(router.query)
    .filter((key: string) => !dynamicParams.includes(key))
    .reduce((acc: Record<string, any>, key: string) => {
      acc[key] = router.query[key];
      return acc;
    }, {});

  const searchParams = new URLSearchParams(filteredQuery as any).toString();
  const search = searchParams ? `?${searchParams}` : '';

  // home page - don't inclide pathname to avoid redirects (poinz.ch/de/ => poinz.ch/de)
  const formattedPathname = pathname === '/' ? '' : pathname;

  const isAuthPage =
    matchWithCurrentPathname(routes.auth.confirmAccount()) ||
    matchWithCurrentPathname(routes.auth.forgotPassword()) ||
    matchWithCurrentPathname(routes.auth.login()) ||
    matchWithCurrentPathname(routes.auth.register()) ||
    matchWithCurrentPathname(routes.auth.resetPassword());

  if (isAuthPage) return null;

  return (
    <>
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${Language.DE}${formattedPathname}${search}`}
        hrefLang="de"
      />
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${Language.EN}${formattedPathname}${search}`}
        hrefLang="en"
      />
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${Language.IT}${formattedPathname}${search}`}
        hrefLang="it"
      />
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${Language.FR}${formattedPathname}${search}`}
        hrefLang="fr"
      />
      <link
        rel="alternate"
        href={`${process.env.NEXT_PUBLIC_HOST}/${Language.DE}${formattedPathname}${search}`}
        hrefLang="x-default"
      />
    </>
  );
};
