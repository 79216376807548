import { useTheme } from 'next-themes';
import { ETheme } from 'utils/theme';

const useIsDarkTheme = () => {
  const { resolvedTheme } = useTheme();
  if (!resolvedTheme) {
    return true;
  }
  return resolvedTheme === ETheme.DARK;
};

export default useIsDarkTheme;
