import { ParsedUrlQuery } from 'querystring';

export const formatUrl = (url: string) => (url.startsWith('http') ? url : `http://${url}`);


export const extractGAQueryParams = (query: ParsedUrlQuery) => {
  if (!query) {
    return null;
  }

  const utmQueryParamsKeys = Object.keys(query).filter(key => key.startsWith('utm'));

  if (!utmQueryParamsKeys.length) {
    return null;
  }

  const utmQueryParams: ParsedUrlQuery = utmQueryParamsKeys.reduce(
    (acc, key) => ({ ...acc, [key]: query[key] }),
    {}
  );

  return utmQueryParams;
};

export const removeHashFromPath = (path: string) => {
  if (typeof path === 'string') {
    return path.split('#')[0];
  }
  return path;
};
