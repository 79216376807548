export const cookieOptions = {
  domain: process.env.APP_ENV === 'PROD' ? '.poinz.ch' : undefined,
  path: '/',
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 2))
};

export enum ECookieKeys {
  LANG = 'NEXT_LOCALE',
  CLOSED_CAMPAIGN_BANNER = 'CLOSED_CAMPAIGN_BANNER',
  CONFIGURE_2FA_BANNER = 'CONFIGURE_2FA_BANNER'
}
