export const POINZ_COMPANY_NAME = 'poinz AG';
export const POINZ_ADDRESS = 'Manessestrasse 170';
export const SWITZERLAND_COUNTRY_CODE = 'CH';
export const POINZ_ZIP = '8045';
export const ZURICH = 'Zürich';
export const POINZ_INFO_EMAIL = 'info@poinz.ch';
export const POINZ_PHONE = '+41 44 515 85 60';
export const POINZ_LONGITUTE = 8.52234545285323;
export const POINZ_LATITUDE = 47.36187362036318;

export const isClientSide = () => typeof window !== 'undefined';

export const parseJwt = (token?: string): Record<string, string> => {
  if (!token) return {};
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// eslint-disable-next-line
export const emptyFunction = async () => {};

export const getSelectOptionsFromEnum = (enumType: any, translations: Record<string, string>) => {
  const options = Object.keys(enumType) as Array<keyof typeof enumType> as Array<string>;
  return options.map(value => {
    return {
      label: translations[value.toLowerCase()],
      value: value
    };
  });
};

export const valuesAreEqual = (obj1: any, obj2: any) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const socketRoot = {
  test: 'wss://api-test.poinz.ch',
  development: 'wss://api-test.poinz.ch',
  staging: 'wss://api-staging.poinz.ch',
  prod: 'wss://api.poinz.ch'
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
