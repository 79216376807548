import * as React from 'react';

interface IMiniCartContext {
  isMiniCartOpen: boolean;
  setIsMiniCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MiniCartContext = React.createContext<IMiniCartContext>({
  isMiniCartOpen: false,
  setIsMiniCartOpen: () => {}
});

interface Props {
  children: React.ReactNode;
}

const MiniCartProvider = ({ children }: Props) => {
  const [isMiniCartOpen, setIsMiniCartOpen] = React.useState(false);

  const contextValue = React.useMemo(
    () => ({
      isMiniCartOpen,
      setIsMiniCartOpen
    }),
    [isMiniCartOpen]
  );

  return <MiniCartContext.Provider value={contextValue}>{children}</MiniCartContext.Provider>;
};

export const useMiniCart = (): IMiniCartContext => {
  const contextValue = React.useContext(MiniCartContext);
  return contextValue;
};
export default MiniCartProvider;
