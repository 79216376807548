import { getCookie } from 'cookies-next';
import { createPartnerClient, buildAgentHeader, setAuthorizationToken } from '@poinz/api';
import { VERSION, env } from 'config';
import { ECookieKeys } from 'utils/cookie';
import { AUTH, getAppCheckToken } from 'utils/firebase';

const setAuthenticatedHeaders = async (headers: Headers) => {
  const idToken = await AUTH.currentUser?.getIdToken();
  if (idToken !== null && typeof idToken === 'string') {
    setAuthorizationToken(headers, '');
    setAuthorizationToken(headers, idToken);
  }
  headers.set('grant_type', 'FIREBASE_AUTH');

  // try {
  //   const token = await getAppCheckToken();
  //   headers.set('X-Firebase-AppCheck', token as string);
  // } catch (e: any) {
  //   console.error("Can't get auth token", e.message);
  // }

  return headers;
};

const parameters = {
  agent: buildAgentHeader({ app: 'web_app', version: VERSION }),
  language: getCookie(ECookieKeys.LANG) as string,
  environment: env,
  setAuthenticatedHeaders
};

const api = createPartnerClient(parameters);

export type IApi = typeof api;

export default api;
