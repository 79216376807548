import { isClientSide } from 'utils/general';
import firebaseApp from './index';
import facebookService from 'utils/facebook';
import { IEntityTypes } from '@poinz/api';

export enum ANALYTIC_EVENTS {
  SIGN_UP = 'sign_up',
  LOGIN = 'login',
  CREDIT_CARD_APPLICATION_STARTED = 'credit_card_application_started',
  SHOP = 'shop',
  FOR_COMPANIES = 'for_companies',
  SHOP_FILTER = 'shop_filter',
  SHOP_SEARCH = 'shop_search',
  SHOP_CATEGORY = 'shopping_category',
  TOGGLE_SHOP_SEARCH = 'toggle_shop_search',
  SHOP_PAGINATION = 'shop_pagination',
  ABOUT_US = 'about_us',
  JOIN_US = 'join_us',
  USER_DRAWER_OPEN = 'user_drawer_open',
  MINI_CART_OPEN = 'mini_cart_open',

  ACCOUNT_OVERVIEW = 'account_overview',
  ACCOUNT_INFORMATION = 'account_information',
  ACCOUNT_CASHBACK = 'account_cashback',
  ACCOUNT_SUPPORT = 'account_support',
  ACCOUNT_WALLET = 'account_wallet',
  ACCOUNT_RECEIPTS = 'account_receipts',
  ACCOUNT_INFO_UPDATED = 'account_information_updated',
  ACCOUNT_DELETION_REQUESTED = 'account_deletion_requested',
  MFA_TOGGLED = 'mfa_toggled',
  ACTIVATE_CASHBACK_CARD = 'activate_cashback_card',
  DELETE_CASHBACK_CARD = 'delete_cashback_card',

  HOME_SIGN_UP = 'home_sign_up',
  HOME_SEARCH = 'home_search',

  SUPPORT_TICKET_CREATED = 'support_ticket_created',
  DOWNLOAD_ANDROID_APP = 'download_android_app',
  DOWNLOAD_IOS_APP = 'download_ios_app',
  FOOTER_ITEM = 'footer_item',
  LANGUAGE_CHANGE = 'language_change',
  SUPPORT_CHAT_OPEN = 'support_chat_open',
  GLOBAL_SEARCH = 'global_search',

  GO_TO_ONLINE_STORE = 'go_to_online_store',
  GO_TO_CREDIT_CARD_PAGE = 'go_to_credit_card_page',

  SELECT_ITEM = 'select_item',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  CHECKOUT_USING_POINZ_BALANCE = 'checkout_using_poinz_balance',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  UPDATE_USER_ADDRESS = 'update_user_address',
  PURCHASE = 'purchase',
  DEAL_REDEMPTION = 'deal_redemption',

  REFERRAL_PAGE_OPEN = 'referral_page_open',
  REFERRAL_APPLY = 'referral_apply',
  REFERRAL_CONFIRM = 'referral_confirm'
}

export enum FOOTER_ANALYTIC_ITEM {
  SHOP = 'shop',
  SHOP_IN_STORE = 'shop_in_store',
  SHOP_ONLINE = 'shop_online',
  SHOP_DEALS = 'shop_deals',
  SHOP_GIFT_CARDS = 'shop_gift_cards',
  POINZ_CARDS = 'poinz_cards',
  PARTNER_LOGIN = 'partner_login',
  BECOME_PARTNER = 'become_partner',
  CASHBACK_BROWSER_EXTENSION = 'cashbak_browser_extension',
  ABOUT_US = 'about_us',
  CONTACT_US = 'contact_us',
  FAQ = 'faq',
  TIKTOK = 'tiktok',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube'
}

export enum PIXEL_EVENTS {
  PURCHASE = 'Purchase',
  INIT_CHECKOUT = 'InitiateCheckout',
  ADD_TO_CART = 'AddToCart',
  VIEW_CONTENT = 'ViewContent',
  PAGE_VIEW = 'PageView',
  COMPLETE_REGISTRATION = 'CompleteRegistration'
}

const getItemType = item => {
  if (item.giftCards) return IEntityTypes.GIFT_CARD;
  if (item.assetType) return IEntityTypes.DEAL;
  if (item.affiliateId) return IEntityTypes.AFFILIATE;
};

export const formatAnalyticItem = item => {
  return {
    item_type: getItemType(item),
    item_id: item?.id || item?.affiliateId || item?.dealId || item?.giftCardId,
    business_profile_name: item?.businessProfile?.name || item?.businessProfileData?.name,
    business_profile_id: item?.businessProfile?.id || item?.businessProfileData?.id,
    ...item
  };
};

export const formatAnalyticItems = items => {
  if (!items) return [];

  return items.map(formatAnalyticItem);
};
const getAnalytics = async () => {
  const getAnalyticsFirebase = (await import('firebase/analytics')).getAnalytics;
  return isClientSide() ? getAnalyticsFirebase(firebaseApp) : null;
};

const logEvent = async (name: ANALYTIC_EVENTS, params?: Record<string, any>) => {
  const firebaseLogEvent = (await import('firebase/analytics')).logEvent;

  const analytics = await getAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, name as string, params);
  }
  switch (name) {
    case ANALYTIC_EVENTS.PURCHASE:
      facebookService.track(PIXEL_EVENTS.PURCHASE, params);
      break;
    case ANALYTIC_EVENTS.ADD_TO_CART:
      facebookService.track(PIXEL_EVENTS.ADD_TO_CART, params);
      break;
    case ANALYTIC_EVENTS.BEGIN_CHECKOUT:
      facebookService.track(PIXEL_EVENTS.INIT_CHECKOUT, params);
      break;
    case ANALYTIC_EVENTS.VIEW_ITEM:
      facebookService.track(PIXEL_EVENTS.VIEW_CONTENT, params);
      break;
    case ANALYTIC_EVENTS.SIGN_UP:
      facebookService.track(PIXEL_EVENTS.COMPLETE_REGISTRATION, params);
      break;
    default:
      break;
  }
};

const setUserId = async (id: string) => {
  const setUserId = (await import('firebase/analytics')).setUserId;

  const analytics = await getAnalytics();

  if (analytics) {
    setUserId(analytics, id);
    facebookService.setUserId(id);
  }
};

// eslint-disable-next-line
export default {
  logEvent,
  setUserId
};
