import { AUTH } from '.';
import { FormattedAuthUser } from 'contexts/authUser/store';

export const getIsSignedInWithEmailAndPassword = () => {
  if (!AUTH.currentUser) return false;
  return !!AUTH.currentUser?.providerData.find(data => data.providerId === 'password');
};

export const isEmailVerified = () => {
  if (!AUTH.currentUser) return false;
  return AUTH.currentUser.emailVerified;
};

export const formatAuthUser = (user): FormattedAuthUser => ({
  uid: user?.uid,
  email: user?.email,
  emailVerified: user?.emailVerified
});
