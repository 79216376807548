import * as React from 'react';
import { VariantProps, cva, cx } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import Link from 'components/Link';

export const buttonVariants = cva(
  // should't use fontSize (text-sm) as default as then we have to override with !important in variants
  // can't remove now it has impact in many places
  // TODO: @Marko consider spliting button / link into two separated components
  '',
  {
    variants: {
      size: {
        default: 'py-3 px-6',
        sm: 'py-3 px-3 text-xs',
        lg: 'py-3 px-6',
        badge: 'font-normal py-2 px-4',
        custom: ''
      },
      variant: {
        default:
          'default-button bg-neutral-200 text-neutral-900 hover:bg-neutral-300 focus:bg-neutral-300 dark:bg-neutral-800 dark:text-neutral-50 hover:dark:bg-neutral-700 focus:dark:bg-neutral-700',
        primary:
          'default-button bg-primary text-white hover:bg-primary-light focus:bg-primary-light disabled:bg-neutral-300 disabled:text-neutral-400 dark:bg-white dark:text-neutral-900 dark:hover:bg-neutral-100 dark:focus:bg-neutral-100 dark:disabled:bg-neutral-300 dark:disabled:text-neutral-400',
        secondary:
          'default-button bg-black hover:bg-neutral-700 focus:bg-neutral-700 text-white disabled:bg-neutral-300 disabled:text-neutral-400 dark:border dark:border-white dark:hover:bg-opacity-10 dark:disabled:border-neutral-500 dark:disabled:text-neutral-500',
        tertiary:
          'default-button bg-white text-primary border border-primary hover:text-primary-light focus:text-primary-light hover:border-primary-light focus:border-primary-light disabled:text-neutral-300 disabled:border-neutral-300',
        quaternary:
          'default-button bg-white text-neutral-900  hover:text-neutral-500 focus:text-neutral-500  disabled:text-neutral-300  dark:bg-neutral-900 dark:text-white dark:hover:text-neutral-200 dark:focus:text-neutral-200 dark:disabled:text-neutral-500',
        fifthly:
          'default-button bg-white/10 text-white border border-white backdrop-blur-[6px] hover:bg-white/20 focus:bg-transparend',
        subtle:
          'default-button bg-transparent dark:bg-transparent text-neutral-900 dark:text-neutral-50 hover:bg-neutral-100 focus:bg-neutral-100 hover:dark:bg-neutral-800 focus:dark:bg-neutral-800',
        ghost:
          'default-button bg-transparent hover:bg-neutral-50 focus:bg-neutral-50 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 text-neutral-900 dark:text-neutral-100 dark:hover:text-neutral-100 dark:focus:text-neutral-100 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent',
        link: 'default-button bg-transparent underline-offset-4 hover:underline hover:bg-transparent dark:hover:bg-transparent focus:underline focus:bg-transparent dark:focus:bg-transparent !px-0 h-auto text-neutral-600 dark:text-neutral-400 ring-neutral-200 focus-visible:ring-2 focus:ring-0',
        'footer-link':
          'default-button text-footer bg-transparent text-neutral-300 hover:!text-white focus:!text-white focus:!text-white !py-0 !px-0 underline-offset-4 hover:bg-transparent focus:bg-transparent h-auto focus:!ring-0 focus:!ring-offset-0 focus-visible:!ring-0 active:!ring-0',
        'primary-badge':
          'default-button text-neutral-900 disabled:text-neutral-400 bg-neutral-100 hover:bg-neutral-50 focus:bg-neutral-50 hover:disabled:bg-neutral-100',
        'secondary-badge':
          'default-button text-white disabled:text-neutral-400 bg-neutral-900 hover:bg-neutral-700 focus:bg-neutral-500 disabled:bg-neutral-300 hover:disabled:bg-neutral-300',
        'tertiary-badge':
          'default-button border border-neutral-900 text-neutral-900 hover:text-neutral-500 hover:border-neutral-500 disabled:text-neutral-300 active:bg-neutral-900 active:text-white disabled:boreder-neutral-300 bg-white',
        'quaternary-badge':
          'default-button text-primary bg-primary-lightest hover:text-primary-lighter focus:text-primary-lighter disabled:text-neutral-400 disabled:bg-neutral-300 hover:disabled:bg-neutral-300',
        custom: ''
      },
      ratio: {
        default: '',
        square: 'aspect-square py-0 px-0',
        rounded: 'aspect-square py-0 px-0 rounded-full'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      ratio: 'default'
    }
  }
);

const ICON_SIZE: Record<'default' | 'sm' | 'lg', number> = {
  default: 18,
  sm: 16,
  lg: 20
};

type ButtonOrAnchorType =
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonProps = ButtonOrAnchorType &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
    disabled?: boolean;
    href?: string;
    query?: Record<string, any>;
  };

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      className,
      variant,
      size = 'default',
      ratio,
      loading = false,
      href,
      query,
      disabled,
      children,
      ...props
    },
    ref
  ) => {
    const renderChildren = () => {
      return (
        <>
          {loading && (
            <span className="mr-2">
              <Loader2 size={size ? ICON_SIZE[size] : 16} className="animate-spin text-inherit" />
            </span>
          )}
          {children}
        </>
      );
    };

    // internal links should be handled with <Link /> component
    if (href && href.startsWith('/')) {
      return (
        <Link
          className={cx(
            buttonVariants({ variant, size, ratio }),
            className,
            loading ? 'pointer-events-none opacity-50' : ''
          )}
          href={
            query
              ? {
                  href,
                  query
                }
              : href
          }
          {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {renderChildren()}
        </Link>
      );
      // external links need to be handled with anchor element
    } else if (href) {
      return (
        <a
          className={cx(
            buttonVariants({ variant, size, ratio }),
            className,
            loading ? 'pointer-events-none opacity-50' : ''
          )}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          href={href}
          {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {renderChildren()}
        </a>
      );
    }

    return (
      <button
        className={cx('Button', variant, buttonVariants({ variant, size, ratio }), className)}
        disabled={disabled || loading}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {renderChildren()}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
