import { Option } from 'types';
import { AppContext } from './store';

const getCreditCard = (state: AppContext) => state.creditCard;
const getFaq = (state: AppContext) => state.creditCard?.faq;
const getNextSteps = (state: AppContext) => state.creditCard?.nextSteps;

const getAppConfig = (state: AppContext) => state.config?.configMap;

const getCityCategoryOptions = (state: AppContext): Array<Option> => {
  const { city_categories } = getAppConfig(state) || {};
  if (!city_categories) return [];
  return city_categories.map(cat => ({
    value: String(cat.id),
    label: cat.name
  }));
};

const getDealCategoryOptions = (state: AppContext): Array<Option> => {
  const { deal_categories } = getAppConfig(state) || {};
  if (!deal_categories) return [];
  return deal_categories.map(cat => ({
    value: String(cat.key),
    label: cat.name
  }));
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCreditCard,
  getFaq,
  getNextSteps,
  getAppConfig,
  getCityCategoryOptions,
  getDealCategoryOptions
};
