import * as React from 'react';
import { authUserSelectors } from 'contexts/authUser';
import { useAuth } from 'contexts/authUser/store';
import { ShoppingCartProvider } from 'contexts';
import LoyaltyProvider from 'contexts/loyalty/store';
import UserAddressesProvider from 'contexts/userAddresses/store';
import SocketProvider from 'contexts/socket';
import useMatchWithCurrentPathname from 'hooks/useMatchWithCurrentPathname';
import { routes } from 'utils/routes';
interface Props {
  children: React.ReactNode;
}

const AuthorizedProviderContainer: React.FC<Props> = ({ children }) => {
  const { isLoggedIn } = useAuth(state => ({
    isLoggedIn: authUserSelectors.isLoggedIn(state)
  }));
  const matchWithCurrentPathname = useMatchWithCurrentPathname();

  const loginOrRegister =
    matchWithCurrentPathname(routes.auth.register()) ||
    matchWithCurrentPathname(routes.auth.login()) ||
    matchWithCurrentPathname(routes.auth.confirmAccount());

  if (!isLoggedIn || loginOrRegister) {
    return <>{children}</>;
  }

  return (
    <SocketProvider>
      <LoyaltyProvider>
        <ShoppingCartProvider>
          <UserAddressesProvider>{children}</UserAddressesProvider>
        </ShoppingCartProvider>
      </LoyaltyProvider>
    </SocketProvider>
  );
};

export default AuthorizedProviderContainer;
