import * as React from 'react';
import {
  IItemBody,
  IShoppingCart,
  ICartItem,
  ICartState,
  IUpdateItemBody,
  ICartAddresses,
  ILoyaltyStatus
} from '@poinz/api';
import api from 'api/api';
import { formatShoppingCartResponse } from 'utils/shoppingCart';
import { useToast } from 'components/Toast';
import { useTranslation } from 'next-i18next';
import { useSocketContext } from 'contexts/socket';
import { ISubscriptionEndpoint, IMessageDataStatus } from 'model/socket';
import { useLoaderOverlayContext } from 'contexts/loaderOverlay';
import { REFFENCE_NUMBER_SLUG, routes } from 'utils/routes';
import useMatchWithCurrentPathname from 'hooks/useMatchWithCurrentPathname';
import { useRouter } from 'next/router';

export interface IShoppingCartContext {
  cart: IShoppingCart | null;
  addItemToCart?: (item: IItemBody) => Promise<void>;
  updateCartItem?: (itemId: number, item: IUpdateItemBody) => Promise<void>;
  removeItemFromCart?: (item: ICartItem) => Promise<void>;
  removeAllItemsFromCart?: () => Promise<void>;
  updateCartState?: (state: ICartState) => Promise<void>;
  loyaltyUsage: boolean;
  updateLoyaltyUsage?: (useLoyaltyState: boolean) => Promise<void>;
  updateCartAddress?: (addresses: ICartAddresses) => Promise<void>;
  loading: boolean;
  getShoppingCart?: () => Promise<void>;
}

const ShoppingCartContext = React.createContext<IShoppingCartContext>({
  cart: null,
  loyaltyUsage: false,
  loading: false
});

interface Props {
  children: React.ReactNode;
}

const ShoppingCartProvider = ({ children }: Props) => {
  const [cart, setCart] = React.useState<IShoppingCart | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { subscribe, unsubscribe } = useSocketContext();
  const { toast } = useToast();
  const { t } = useTranslation();
  const { hideOverlayLoader } = useLoaderOverlayContext();
  const router = useRouter();
  const isFetchingShoppingCart = React.useRef(false);
  const matchWithCurrentPathname = useMatchWithCurrentPathname();

  const handleError = React.useCallback(
    (error: unknown) => {
      const e = JSON.parse(error as string);
      toast({
        title: e.key ? (t(e.key) as string) : (t('api.error') as string),
        variant: 'error'
      });
      throw e;
    },
    [t, toast]
  );

  const updateCartState = React.useCallback(
    async (cartId: number, state: ICartState) => {
      try {
        setLoading(true);
        const response = await api.shoppingCart.cart.updateCheckoutState(cartId, state);
        setCart(formatShoppingCartResponse(response));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [handleError]
  );

  const addItemToCart = React.useCallback(
    async (cartId: number, item: IItemBody) => {
      if (cart?.state === ICartState.CHECKOUT) {
        await updateCartState(cartId, ICartState.SHOPPING);
      }

      try {
        setLoading(true);
        const response = await api.shoppingCart.cart.addItem(cartId, item);
        setCart(formatShoppingCartResponse(response));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [cart?.state, handleError, updateCartState]
  );

  const updateCartItem = React.useCallback(
    async (cartId: number, itemId: number, item: IUpdateItemBody) => {
      if (cart?.state === ICartState.CHECKOUT) {
        await updateCartState(cartId, ICartState.SHOPPING);
      }

      try {
        setLoading(true);
        const response = await api.shoppingCart.cart.updateItem(cartId, itemId, item);
        setCart(formatShoppingCartResponse(response));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [cart?.state, handleError, updateCartState]
  );

  const removeItemFromCart = React.useCallback(
    async (cartId: number, item: ICartItem) => {
      try {
        setLoading(true);
        const response = await api.shoppingCart.cart.removeItem(cartId, item.id);
        setCart(formatShoppingCartResponse(response));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [handleError]
  );

  const removeAllItemsFromCart = React.useCallback(
    async (cartId: number) => {
      try {
        setLoading(true);
        const response = await api.shoppingCart.cart.removeAllItems(cartId);
        setCart(formatShoppingCartResponse(response));
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [handleError]
  );

  const updateLoyaltyUsage = React.useCallback(
    async (cartId: number, useLoyaltyState: boolean) => {
      try {
        setLoading(true);
        const { useLoyalty: updatedUseLoyaltyState } =
          await api.shoppingCart.cart.updateLoyaltyUsage(cartId, useLoyaltyState);

        setCart(currentCart => {
          if (currentCart) {
            return {
              ...currentCart,
              loyaltyStatus: updatedUseLoyaltyState
                ? ILoyaltyStatus.REQUESTED
                : ILoyaltyStatus.NOT_USED
            };
          }

          return null;
        });
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [handleError]
  );

  const updateCartAddress = React.useCallback(
    async (cartId: number, addresses: ICartAddresses) => {
      try {
        setLoading(true);
        const updatedCartAddress = await api.shoppingCart.cart.updateAddress(cartId, addresses);
        setCart(current => current && { ...current, ...updatedCartAddress });
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [handleError]
  );

  const loyaltyUsage = React.useMemo(
    () =>
      !!(
        cart?.loyaltyStatus &&
        [ILoyaltyStatus.REQUESTED, ILoyaltyStatus.RESERVED].includes(cart?.loyaltyStatus)
      ),
    [cart?.loyaltyStatus]
  );

  const getShoppingCart = React.useCallback(async () => {
    if (isFetchingShoppingCart.current) {
      return;
    }

    isFetchingShoppingCart.current = true;

    try {
      setLoading(true);
      const rawCart = await api.shoppingCart.cart.get();
      // make exception when user lands on SUCCESS page as there is possibility that cart is still in PURCHASE state
      // don't want to update state into CHECKOUT as SUCCESS message will force fetching shopping cart
      if (
        rawCart.state === ICartState.PURCHASE &&
        !matchWithCurrentPathname(routes.checkout.success(REFFENCE_NUMBER_SLUG))
      ) {
        await updateCartState(rawCart.id, ICartState.CHECKOUT);
        return;
      }
      setCart(formatShoppingCartResponse(rawCart));
    } catch (error) {
      handleError(error);
    } finally {
      isFetchingShoppingCart.current = false;
      setLoading(false);
    }
  }, [handleError, matchWithCurrentPathname, updateCartState]);

  React.useEffect(() => {
    // only fetch shopping cart once (if it doesn't exists)
    // later we are updating on users actions or as per socket requests
    if (!cart) {
      getShoppingCart();
    }
  }, [cart, getShoppingCart]);

  React.useEffect(() => {
    const subscription =
      subscribe &&
      subscribe(ISubscriptionEndpoint.USER_QUEUE_PUSH, async message => {
        console.debug({ message }, 'shopping cart subscription');
        if (
          [
            IMessageDataStatus.RESERVATION_SUCCESS,
            IMessageDataStatus.PURCHASE_FAILED,
            IMessageDataStatus.PURCHASE_SUCCESS,
            IMessageDataStatus.PURCHASE_EXPIRED,
            IMessageDataStatus.PURCHASE_CANCELED
          ].includes(message.data.status as IMessageDataStatus)
        ) {
          await getShoppingCart();
          hideOverlayLoader();
        }

        if (message.data.status === IMessageDataStatus.RESERVATION_FAILED) {
          if (cart) {
            await updateCartState(cart?.id, ICartState.SHOPPING);
            toast({
              title: t('cart.error.reservationFailed') as string,
              variant: 'error'
            });
            router.replace(routes.checkout.overview());
          }
          hideOverlayLoader();
        }
      });

    return () => {
      if (unsubscribe) {
        unsubscribe(subscription);
      }
    };
  }, [
    cart,
    getShoppingCart,
    router,
    toast,
    t,
    hideOverlayLoader,
    subscribe,
    unsubscribe,
    updateCartState
  ]);

  const contextValue = React.useMemo(
    () => ({
      cart,
      loyaltyUsage,
      loading,
      getShoppingCart,
      ...(cart?.id && {
        addItemToCart: (item: IItemBody) => addItemToCart(cart.id, item),
        updateCartItem: (itemId: number, item: IUpdateItemBody) =>
          updateCartItem(cart.id, itemId, item),
        removeItemFromCart: (cartItem: ICartItem) => removeItemFromCart(cart.id, cartItem),
        removeAllItemsFromCart: () => removeAllItemsFromCart(cart.id),
        updateCartState: (state: ICartState) => updateCartState(cart.id, state),
        updateLoyaltyUsage: (useLoyaltyState: boolean) =>
          updateLoyaltyUsage(cart.id, useLoyaltyState),
        updateCartAddress: (addresses: ICartAddresses) => updateCartAddress(cart.id, addresses)
      })
    }),
    [
      cart,
      loyaltyUsage,
      loading,
      getShoppingCart,
      addItemToCart,
      updateCartItem,
      removeItemFromCart,
      removeAllItemsFromCart,
      updateCartState,
      updateLoyaltyUsage,
      updateCartAddress
    ]
  );

  return (
    <ShoppingCartContext.Provider value={contextValue}>{children}</ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = (): IShoppingCartContext => {
  const contextValue = React.useContext(ShoppingCartContext);

  return contextValue;
};
export default ShoppingCartProvider;
