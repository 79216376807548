enum FirebaseErrorCodes {
  EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  INVALID_ACTION_CODE = 'auth/invalid-action-code',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL = 'auth/account-exists-with-different-credential',
  WRONG_PASSWORD = 'auth/wrong-password',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  INVALID_LOGIN_CREDENTIALS = 'auth/invalid-login-credentials',
  INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use'
}

const FirebaseErrorCodeTranslations = {
  [FirebaseErrorCodes.EXPIRED_ACTION_CODE]: `firebase.auth.error.${FirebaseErrorCodes.EXPIRED_ACTION_CODE}`,
  [FirebaseErrorCodes.INVALID_ACTION_CODE]: `firebase.auth.error.${FirebaseErrorCodes.INVALID_ACTION_CODE}`,
  [FirebaseErrorCodes.USER_DISABLED]: `firebase.auth.error.${FirebaseErrorCodes.USER_DISABLED}`,
  [FirebaseErrorCodes.USER_NOT_FOUND]: `firebase.auth.error.${FirebaseErrorCodes.USER_NOT_FOUND}`,
  [FirebaseErrorCodes.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL]: `firebase.auth.error.${FirebaseErrorCodes.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL}`,
  [FirebaseErrorCodes.WRONG_PASSWORD]: `firebase.auth.error.${FirebaseErrorCodes.WRONG_PASSWORD}`,
  [FirebaseErrorCodes.TOO_MANY_REQUESTS]: `firebase.auth.error.${FirebaseErrorCodes.TOO_MANY_REQUESTS}`,
  [FirebaseErrorCodes.INVALID_LOGIN_CREDENTIALS]: `firebase.auth.error.${FirebaseErrorCodes.INVALID_LOGIN_CREDENTIALS}`,
  [FirebaseErrorCodes.INVALID_PHONE_NUMBER]: `firebase.auth.error.${FirebaseErrorCodes.INVALID_PHONE_NUMBER}`,
  [FirebaseErrorCodes.EMAIL_ALREADY_IN_USE]: `firebase.auth.error.${FirebaseErrorCodes.EMAIL_ALREADY_IN_USE}`
};

export const getFirebaseErrorMessage = (error: { code?: FirebaseErrorCodes }) => {
  if (!error.code) return null;

  return FirebaseErrorCodeTranslations[error.code]
    ? FirebaseErrorCodeTranslations[error.code]
    : 'firebase.auth.error.general';
};
