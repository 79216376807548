import * as React from 'react';
import {
  ILoaderOverlay,
  ILoaderOverlayTextContent,
  ILoaderOverlayContext
} from 'model/loaderOverlay';
import clsx from 'clsx';
import { inter } from 'pages/_app';

const defaultValue = {
  isLoading: false
};

const LoaderOverlayContext = React.createContext<ILoaderOverlayContext>({
  loader: defaultValue,
  showOverlayLoader: () => {},
  hideOverlayLoader: () => {}
});

interface Props {
  children: React.ReactNode;
}

const LoaderOverlayProvider = ({ children }: Props) => {
  const [loader, setLoader] = React.useState<ILoaderOverlay>(defaultValue);

  const showOverlayLoader = React.useCallback((textContent?: ILoaderOverlayTextContent) => {
    setLoader({ isLoading: true, ...textContent });
  }, []);

  const hideOverlayLoader = React.useCallback(() => {
    setLoader(defaultValue);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      loader,
      showOverlayLoader,
      hideOverlayLoader
    }),
    [loader, showOverlayLoader, hideOverlayLoader]
  );

  return (
    <LoaderOverlayContext.Provider value={contextValue}>
      <>
        {children}
        {loader.isLoading && (
          <div
            className={clsx(
              'fixed flex flex-col justify-center items-center w-full h-full left-0 top-0 right-0 bottom-0 z-[100] bg-white',
              inter.className
            )}
          >
            <div
              style={{ borderWidth: '5px', borderTopColor: 'white' }}
              className="h-14 w-14 animate-spin rounded-full border border-neutral-900 mb-8"
            />
            {loader.title && <p className=" text-heading-tiny mb-2 md:mb-1">{loader.title}</p>}
            {loader.body && <p className=" text-body1">{loader.body}</p>}
          </div>
        )}
      </>
    </LoaderOverlayContext.Provider>
  );
};

export const useLoaderOverlayContext = (): ILoaderOverlayContext => {
  const contextValue = React.useContext(LoaderOverlayContext);

  return contextValue;
};
export default LoaderOverlayProvider;
