import * as React from 'react';
import { useRouter } from 'next/router';

const useMatchWithCurrentPathname = () => {
  const { pathname: currentPathname } = useRouter();
  return React.useCallback(
    (pathname: string): boolean => currentPathname === pathname,
    [currentPathname]
  );
};

export default useMatchWithCurrentPathname;
