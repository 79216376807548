import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebaseConfig from './config';
import {
  AppCheck,
  ReCaptchaEnterpriseProvider,
  getToken,
  initializeAppCheck
} from 'firebase/app-check';
import { isClientSide } from 'utils/general';

const firebaseApp = initializeApp(firebaseConfig);
let appCheck: AppCheck | null = null;
if (isClientSide()) {
  appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
}

export const getAppCheckToken = async () => {
  try {
    if (appCheck) {
      const { token } = await getToken(appCheck, false);
      return token;
    }
  } catch (error) {
    console.error('Error getting App Check token:', error);
  }
};

export const AUTH = getAuth(firebaseApp);

export default firebaseApp;
