import React, { createContext } from 'react';
import { useAuth } from 'contexts/authUser/store';
import useApi from 'hooks/useApi';
import { authUserSelectors } from 'contexts/authUser';
import { Client, StompSubscription } from '@stomp/stompjs';
import { ISocketContext, IMessage, ISubscriptionEndpoint } from 'model/socket';
import { socketRoot } from 'utils/general';
import { env } from 'config';

export interface Props {
  children: React.ReactNode;
}

const SocketConnetctionContext = createContext<ISocketContext>({
  isLoading: false,
  isConnected: false
});

export const useSocketContext = (): ISocketContext => {
  const contextValue = React.useContext(SocketConnetctionContext);

  return contextValue;
};

// create client object
const client = new Client();

const SocketProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { api } = useApi();

  const { isLoggedIn } = useAuth(state => ({
    isLoggedIn: authUserSelectors.isLoggedIn(state)
  }));

  const [isConnected, setIsConnected] = React.useState(false);

  const initStompClient = React.useCallback(async () => {
    if (isLoggedIn && api && !isConnected) {
      // deactivate on init to be sure that activate will be executed so we can update Ticket!
      client.deactivate();
      setIsLoading(true);
      try {
        //callApi to get token for sockets
        const { ticket } = await api.socket.ticket.get();

        // set client configuration
        client.configure({
          brokerURL: `${socketRoot[env]}/websocket-notification/websocket?ticket=${ticket}`,
          debug: dbg => console.debug({ dbg }),
          // onConnect Callback, invoked on every successful connection to the STOMP broker
          onConnect: arg => {
            console.debug('onConnect', { isConnected, arg });
            setIsConnected(true);
          },
          onDisconnect: () => {
            console.debug('onDisconnect', { isConnected });
            setIsConnected(false);
          },
          beforeConnect: () => {
            console.debug('beforeConnect', { isConnected });
            setIsConnected(false);
          },
          onStompError: err => {
            console.debug('onStompError', { isConnected, err });
            setIsConnected(false);
          },
          onWebSocketClose: arg => {
            console.debug('onWebSocketClose', { isConnected, arg });
            setIsConnected(false);
          },
          onWebSocketError: arg => {
            console.debug('onWebSocketError', { isConnected, arg });
            setIsConnected(false);
          }
        });

        // once we have configuration with brokerUrl it's safe to activate (connect)
        client.activate();
        setIsLoading(false);
      } catch (error) {
        console.error({ error });
      }
    }
  }, [api, isConnected, isLoggedIn]);

  React.useEffect(() => {
    initStompClient();

    async () => {
      // deactivate on onmount no need to have active instance when provider doesn't exist
      await client.deactivate();
    };
  }, [initStompClient]);

  const subscribe = React.useCallback(
    (path: ISubscriptionEndpoint, callback: (message: IMessage) => void) => {
      if (!isConnected) {
        return;
      }
      try {
        const stompSubscription = client.subscribe(path, message => {
          const body: IMessage = JSON.parse(message.body);
          callback(body);
        });

        return stompSubscription;
      } catch (error) {
        // if subscribe fails we should handle something like setIsConnected(false)?
        console.error({ error });
      }
    },
    [isConnected]
  );

  const unsubscribe = React.useCallback((subscription?: StompSubscription) => {
    subscription?.unsubscribe();
  }, []);

  const contextValue = React.useMemo(
    () => ({
      isLoading,
      isConnected,
      ...(isConnected && {
        subscribe,
        unsubscribe
      })
    }),
    [isConnected, isLoading, subscribe, unsubscribe]
  );

  return (
    <SocketConnetctionContext.Provider value={contextValue}>
      {children}
    </SocketConnetctionContext.Provider>
  );
};

export default SocketProvider;
