import { isClientSide } from './general';

export type LocalStorageKey = '';

export enum SessionStorageKey {
  GA_QUERY_PARAMS = 'GA_QUERY_PARAMS'
}

export const setLocalStorageItem = (key: LocalStorageKey, value: any) => {
  if (isClientSide()) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      throw `Can't set ${value} in local storage.\nError: ${JSON.stringify(e)}`;
    }
  }
};

export const getLocalStorageItem = (key: LocalStorageKey) => {
  if (isClientSide()) {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      return null;
    } catch (e) {
      throw `Can't get ${key} from local storage.\nError: ${JSON.stringify(e)}`;
    }
  }
  return null;
};

export const removeLocalStorageItem = (key: LocalStorageKey) => {
  if (isClientSide()) {
    localStorage.removeItem(key);
  }
};

export const setSessionStorageItem = (key: SessionStorageKey, value: any) => {
  if (isClientSide()) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      throw `Can't set ${value} in session storage.\nError: ${JSON.stringify(e)}`;
    }
  }
};

export const getSessionStorageItem = (key: SessionStorageKey) => {
  if (isClientSide()) {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }
  return null;
};

export const removeSessionStorageItem = (key: SessionStorageKey) => {
  if (isClientSide()) {
    sessionStorage.removeItem(key);
  }
};
