import React, { createContext } from 'react';
import { useRouter } from 'next/router';
import { CreditCard, AppConfig } from 'model';
import useApi from 'hooks/useApi';
import { emptyFunction, valuesAreEqual } from 'utils/general';
import { routes } from 'utils/routes';

interface Props {
  children: React.ReactNode;
  creditCard?: CreditCard | null;
  promotedReferralCampaignCode: string | null;
}

export enum HOMEPAGE_BG_COLOR {
  DARK = 'DARK',
  LIGHT = 'LIGHT'
}

export enum HOMEPAGE_SECTION_ID {
  ALWAYS = 'ALWAYS',
  AUTOMATIC = 'AUTOMATIC',
  FREE = 'FREE',
  CASH_PAYOUT = 'CASH_PAYOUT'
}

export interface HomepageActiveSection {
  color: HOMEPAGE_BG_COLOR;
  id: HOMEPAGE_SECTION_ID;
}

export const initHomePageActiveSection: HomepageActiveSection = {
  color: HOMEPAGE_BG_COLOR.LIGHT,
  id: HOMEPAGE_SECTION_ID.ALWAYS
};

export interface AppContext {
  config?: AppConfig | null;
  isHeaderTransparent: boolean;
  setIsHeaderTransparent: (isTransparent: boolean) => void;
  homepageActiveSection: HomepageActiveSection;
  setHomepageActiveSection: (section: HomepageActiveSection) => void;
  setConfig: (userBalance: AppConfig | null) => void;
  creditCard?: CreditCard | null;
  setCreditCard: (card: CreditCard) => void;
  promotedReferralCampaignCode: string | null;
}

const AppContext = createContext<AppContext>({
  config: null,
  isHeaderTransparent: false,
  setIsHeaderTransparent: emptyFunction,
  homepageActiveSection: initHomePageActiveSection,
  setHomepageActiveSection: emptyFunction,
  setConfig: emptyFunction,
  creditCard: null,
  setCreditCard: emptyFunction,
  promotedReferralCampaignCode: null
});

export const useAppContext = (shapeFn?: (state: AppContext) => any): any => {
  const state = React.useContext(AppContext);

  if (shapeFn) {
    return shapeFn(state);
  }

  return state;
};

const AppProvider = ({
  children,
  creditCard: defaultCreditCard,
  promotedReferralCampaignCode: defaultPromotedReferralCampaignCode
}: Props) => {
  const { replace, push, query } = useRouter();
  const [creditCard, setCreditCard] = React.useState(defaultCreditCard);
  const [promotedReferralCampaignCode, setPromotedReferralCampaignCode] = React.useState(
    defaultPromotedReferralCampaignCode
  );

  const [isHeaderTransparent, setIsHeaderTransparent] = React.useState(true);
  const [homepageActiveSection, setHomepageActiveSection] =
    React.useState(initHomePageActiveSection);
  const [config, setConfig] = React.useState<AppConfig | null>(null);
  const { api } = useApi();

  React.useEffect(() => {
    if (!valuesAreEqual(defaultCreditCard, creditCard)) {
      setCreditCard(defaultCreditCard);
    }
  }, [defaultCreditCard, creditCard]);

  React.useEffect(() => {
    if (promotedReferralCampaignCode !== defaultPromotedReferralCampaignCode) {
      setPromotedReferralCampaignCode(promotedReferralCampaignCode);
    }
  }, [defaultPromotedReferralCampaignCode, promotedReferralCampaignCode]);

  const getConfig = React.useCallback(async () => {
    if (api) {
      const data = await api.backend.webConfig.get();
      if (!config || !valuesAreEqual(data.configMap, config.configMap)) {
        // remove non existing cityId from query if needed
        if (query?.cityId) {
          const selectedCity = data.configMap.city_categories?.find(
            city => city.id === Number(query?.cityId)
          );
          if (!selectedCity) {
            delete query.cityId;
            replace({ query }, undefined, {
              shallow: true
            });
          }
        }

        // remove non existing category from query if needed
        if (query?.category) {
          const selectedCategory = data.configMap.deal_categories?.find(
            city => city.key.toLowerCase() === query?.category
          );

          if (!selectedCategory) {
            delete query.category;
            push({ pathname: routes.shop.index(), query }, undefined, {
              shallow: true
            });
          }
        }
        setConfig(data);
      }
    }
  }, [api, config, push, query, replace]);

  React.useEffect(() => {
    getConfig();
  }, [getConfig]);

  const value: AppContext = {
    creditCard,
    setCreditCard,
    config,
    setConfig,
    isHeaderTransparent,
    setIsHeaderTransparent,
    homepageActiveSection,
    setHomepageActiveSection,
    promotedReferralCampaignCode
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
