import * as React from 'react';
import { IUserBalance, IPaymentMethod } from '@poinz/api';
import api from 'api/api';
import { useSocketContext } from 'contexts/socket';
import { ISubscriptionEndpoint, IMessageEntityType, IMessageDataType } from 'model/socket';
import { emptyFunction } from 'utils/general';

export interface ILoylatyContext {
  userBalance: IUserBalance | null;
  paymentMethods: IPaymentMethod[] | null;
  loadingUserBalance: boolean;
  loadingPaymentMethods: boolean;
  getPaymentMethods: () => Promise<void>;
  getUserBalance: () => Promise<void>;
}

const LoylatyContext = React.createContext<ILoylatyContext>({
  userBalance: null,
  paymentMethods: null,
  loadingUserBalance: false,
  loadingPaymentMethods: true,
  getPaymentMethods: emptyFunction,
  getUserBalance: emptyFunction
});

interface Props {
  children: React.ReactNode;
}

const LoyaltyProvider = ({ children }: Props) => {
  const [userBalance, setUserBalance] = React.useState<IUserBalance | null>(null);
  const [loadingUserBalance, setLoadingUserBalance] = React.useState(false);

  const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethod[] | null>(null);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = React.useState(false);
  const { subscribe, unsubscribe } = useSocketContext();

  const getUserBalance = React.useCallback(async () => {
    try {
      setLoadingUserBalance(true);
      const balance = await api.loyalty.userBalance.getMobileBalance();
      setUserBalance(balance);
    } catch (error) {
      // handle error?
    } finally {
      setLoadingUserBalance(false);
    }
  }, []);

  const getPaymentMethods = React.useCallback(async () => {
    setLoadingPaymentMethods(true);
    try {
      const pm = await api.worldLine.paymentMethods.get();
      setPaymentMethods(pm);
    } catch (e) {
      //TODO handle error
    } finally {
      setLoadingPaymentMethods(false);
    }
  }, []);

  React.useEffect(() => {
    getUserBalance();
  }, [getUserBalance]);

  React.useEffect(() => {
    const subscription =
      subscribe &&
      subscribe(ISubscriptionEndpoint.USER_QUEUE_PUSH, async message => {
        console.debug({ message }, 'loyalty store subscription');
        if (
          message.data.entityType === IMessageEntityType.SWISS_LOYALTY_TRANSACTION &&
          message.data.messageType === IMessageDataType.REFRESH
        ) {
          getUserBalance();
        }
      });

    return () => {
      if (unsubscribe) {
        unsubscribe(subscription);
      }
    };
  }, [getUserBalance, subscribe, unsubscribe]);

  const contextValue = React.useMemo(
    () => ({
      userBalance,
      paymentMethods,
      loadingUserBalance,
      loadingPaymentMethods,
      getPaymentMethods,
      getUserBalance
    }),
    [
      userBalance,
      paymentMethods,
      loadingUserBalance,
      loadingPaymentMethods,
      getPaymentMethods,
      getUserBalance
    ]
  );

  return <LoylatyContext.Provider value={contextValue}>{children}</LoylatyContext.Provider>;
};

export const useLoyalty = (): ILoylatyContext => {
  const contextValue = React.useContext(LoylatyContext);

  return contextValue;
};
export default LoyaltyProvider;
