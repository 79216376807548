import * as React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useProtectedRoutes } from 'hooks';
import { SessionStorageKey, setSessionStorageItem } from 'utils/storage';
import { extractGAQueryParams } from 'utils/url';
import MiniCartProvider from 'contexts/miniCart/store';
import { darkBackgroundRoutes } from 'utils/routes';

const CrispWithNoSSR = dynamic(() => import('components/Crisp'), { ssr: false });
const Footer = dynamic(() => import('components/Footer'));
const Header = dynamic(() => import('components/Header'));
const Alert = dynamic(() => import('components/Alert'));
const Toaster = dynamic(() => import('components/Toast').then(module => module.Toaster));

interface Props {
  Component: any;
  pageProps: any;
  className?: string;
}

const App: React.FC<Props> = ({ Component, pageProps, className }) => {
  useProtectedRoutes();
  const router = useRouter();

  const handleExitPreview = React.useCallback(async () => {
    await router.push({ pathname: '/api/exit-preview', query: router.query });
    window.location.reload();
  }, [router]);

  const backgroundColor = React.useMemo(
    () => (darkBackgroundRoutes.includes(router.pathname) ? 'bg-neutral-900' : 'bg-white'),
    [router.pathname]
  );

  React.useEffect(() => {
    const utmQueryParams = extractGAQueryParams(router.query);
    if (utmQueryParams) {
      setSessionStorageItem(SessionStorageKey.GA_QUERY_PARAMS, utmQueryParams);
    }
  }, [router.query]);

  return (
    <>
      <main
        className={clsx(
          className,
          `App flex flex-col min-w-[100vw] min-h-[100vh] w-full h-full ${backgroundColor} dark:bg-neutral-900 font-sans`
        )}
      >
        <CrispWithNoSSR />
        <MiniCartProvider>
          <Header />
          {pageProps.preview && (
            <Alert
              action={{
                onClick: handleExitPreview,
                label: 'general.exit'
              }}
              type="INFO"
              title="general.preview.youAreInPreviewMode"
            />
          )}
          <div className="flex flex-1">
            <Component {...pageProps} />
          </div>
        </MiniCartProvider>
        <Footer className="mt-24 md:mt-32" />
      </main>
      <Toaster />
    </>
  );
};

export default App;
