import firebaseApp from './index';

const getInstallations = async () => {
  const getInstallationsFirebase = (await import('firebase/installations')).getInstallations;
  return getInstallationsFirebase(firebaseApp);
};

export const getDeviceId = async () => {
  const getId = (await import('firebase/installations')).getId;
  const INSTALLATIONS = await getInstallations();

  return getId(INSTALLATIONS);
};
