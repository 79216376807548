import { AuthUserContext } from './store';

const isLoggedIn = (state: AuthUserContext) => {
  const { loading, authUser } = state;
  return !loading && !!authUser;
};

const selectors = {
  isLoggedIn
};

export default selectors;
